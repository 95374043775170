<template>
    <!-- Modal -->
    <div class="modal fade muySellModal"
        :class="[{ 'show': $parent.copyStrategy && !stopStrategy && !successCopy && !stopStrategyConfirm }]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content position-relative border-0 radius20">
                <Loader :classname="'innerLoader'" v-if="tradesLoading"></Loader>
                <div class="modal-header radius20 bg-white border-0 pb-0">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="toggleSlidebutton d-inline-flex align-items-center justify-content-center">
                            <h6 class="f-20 bold mb-0">{{ isAlreadyCopied ? $t('copy_strategy.updateTitle') :
            $t('copy_strategy.copyTitle') }} {{ store.traderDetail.trader.stats.profile.name }}</h6>
                        </div>
                    </div>
                    <button type="button" class="btn-close"
                        @click="$parent.copyStrategy = false; errorMsgNoPlan = false; errorMsgNoSlots = false; errorMsgsutability = false"></button>
                </div>
                <div class="modal-body pb-2">
                    <div class="flex-between align-items-start border-bottom pb-2 mb-2">
                        <div class="tottalValue"
                            v-if="Object.keys(store.userSelectedAccount).length && !isAlreadyCopied">
                            <div class="d-flex align-items-center">
                                <v-lazy-image width="30" v-if="store.userSelectedAccount.brokerLogo"
                                    :src="'https://zulutrade.com/' + store.userSelectedAccount.brokerLogo"
                                    :alt="store.userSelectedAccount.brokerName" />
                                <p class="mb-0 f-14 ms-1">{{ store.userSelectedAccount.username }}</p>
                            </div>
                            <p class=" mb-0">{{ $t('changes.text44') }} {{ COMMON.getBaseCurrencySymbol() }}{{ availCapital
            || 0 }}</p>
                        </div>
                        <div class="tottalValue" width="30"
                            v-if="Object.keys(updatedAccount).length && isAlreadyCopied">
                            <div class="d-flex align-items-center">
                                <v-lazy-image v-if="updatedAccount.brokerLogo"
                                    :src="'https://zulutrade.com/' + updatedAccount.brokerLogo"
                                    :alt="updatedAccount.brokerName" />
                                <p class="mb-0 f-14 ms-1">{{ updatedAccount.username }}</p>
                            </div>
                            <p class=" mb-0">{{ $t('changes.text44') }} {{ COMMON.getBaseCurrencySymbol() }}{{ availCapital
            || 0 }}</p>
                        </div>
                        <div class="position-relative w-50 text-end" v-if="accountList().length">
                            <a href="javascript:void(0)" class="f-15 mb-0 secondary showClass"
                                @click="showAccount = !showAccount">{{ $t('copy_strategy.changeAccount') }}</a>
                            <ul class="dropdown_menu_animated py-2" :class="showAccount ? 'show' : ''">
                                <li :class="[{ 'disabled': list.connectivityStatus !== 'Connected' }]"
                                    v-for="list, key in accountList()" :key="key" @click="changeBroker(list)"><a
                                        class="d-flex align-items-center" href="javascript:void(0)">
                                        <v-lazy-image v-if="list.brokerLogo" width="30"
                                            :src="'https://zulutrade.com/' + list.brokerLogo" class="me-1"
                                            :alt="list.brokerLogo" />{{ list.username }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="!store.userSelectedAccount.demo && !isAlreadyCopied && !stillpendngOnbor()"
                        class="mt-2 py-2 text-warning text-center">
                        {{ $t('copy_strategy.stillPending') }}
                    </div>
                    <div v-else-if="!store.userSelectedAccount.demo && !isAlreadyCopied && errorMsgNoSlots"
                        class="mt-2 pb-2 text-center">
                        <h4 class="medium mb-0">{{ $t('copy_strategy.copyTradingLimitReached') }}</h4>
                        <p class="mb-0 f-14">{{ $t('copy_strategy.pleaseUpgrade') }}</p>
                    </div>
                    <div v-else-if="!store.userSelectedAccount.demo && !isAlreadyCopied && errorMsgNoPlan"
                        class="mt-2 pb-2 text-center">
                        <h4 class="medium mb-0">{{ $t('copy_strategy.noSubscriptionPlan') }}.</h4>
                        <!-- <p class="mb-0 f-14">Please Complete your Onboarding Process.</p> -->
                    </div>
                    <div class="specificPrice" v-else-if="stillpendngOnbor() && !errorMsgNoSlots && !errorMsgNoPlan">
                        <div class="investmentAmount my-2">
                            <h6 class="mb-2 f-15 regular" :title="$t('copy_strategy.totalAmountInvest')">
                                {{ $t('copy_strategy.investmentAmount') }}</h6>
                            <div class="showPrice">
                                <div class="form-group position-relative">
                                    <input type="text" class="form-control text-start largeFont" v-model="amount"
                                        name="amount"
                                        @input="isAlreadyCopied ? onAmountChange() : ''; allowedDotComma($event, 'amount')"
                                        @blur="removeLastDotComma('amount')" />
                                    <span class="position-absolute f-14 secondary medium right pointer"
                                        @click="maxCurrentAmount()">{{ $t('copy_strategy.max') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="investmentAmount my-2"> <!-- ProRata input field -->
                            <h6 class="mb-2 f-15 regular" :title="$t('copy_strategy.zuluTradeAutomatically')">
                                {{ $t('copy_strategy.customCopyRatio') }}</h6>
                            <div class="showPrice">
                                <div class="form-group position-relative">
                                    <input type="text" class="form-control text-start largeFont" v-model="copyratio"
                                        name="copyratio" @input="allowedDotComma($event, 'copyratio')"
                                        @blur="removeLastDotComma('copyratio')" />
                                    <span class="position-absolute f-14 secondary medium right pointer">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="advanceState" :class="advanceState ? 'active' : ''">
                            <a @click.prevent="advanceState = !advanceState"
                                class="flex-between mb-0 bg-card dropButton medium"
                                href="javascript:void(0)">{{ $t('copy_strategy.advancedSettings') }} <vue-feather
                                    size="15" type="chevron-down"></vue-feather></a>
                            <div class="dropdown_menu_animated position-relative box-shadow-none"
                                :class="advanceState ? 'show' : ''">
                                <div class="form-group position-relative mb-4">
                                    <h6 class="f-15 regular" :title="$t('copy_strategy.enterProfityouWant')">
                                        {{ $t('copy_strategy.takeProfit') }}</h6>
                                    <div class="position-relative updateInput">
                                        <label class="upperInputNew">{{ COMMON.getBaseCurrencySymbol() }}</label>
                                        <input type="text" class="form-control" v-model="takeProfitPrice"
                                            name="takeProfitPrice" @input="allowedDotComma($event, 'takeProfitPrice')"
                                            @blur="removeLastDotComma('takeProfitPrice')" />
                                    </div>
                                </div>
                                <div class="stopLoss flex-between border-bottom pb-2 mb-2">
                                    <p class="mb-0 medium" :title="$t('copy_strategy.selectingOption')">
                                        {{ $t('copy_strategy.trailingStopLoss') }}</p>
                                    <label class="switch">
                                        <input type="checkbox" v-model="stopLoss" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div class="stopLoss flex-between" v-if="!isAlreadyCopied">
                                    <p class="mb-0 medium">{{ $t('copy_strategy.copyOpenTrades') }}</p>
                                    <label class="switch">
                                        <input type="checkbox" v-model="copytrades" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footers p-3 border-0 d-flex align-items-center">
                    <a href="javascript:void(0)" class="button w-50 d-flex bold fillBtn border-button zulu_btn me-2"
                        v-if="isAlreadyCopied"
                        @click="stopStrategyConfirm = true">{{ $t('copy_strategy.stopStrategy') }}</a>
                    <a href="javascript:void(0)" class="button w-50 d-flex bold fillBtn zulu_btn me-2"
                        v-if="isAlreadyCopied" @click="updateStategy()"
                        :class="[{ 'disabled': !amount || parseInt(amount) <= 0 || !copyratio || copyratio <= 0 || copyratio > 1000 }]">{{ $t('copy_strategy.update') }}</a>
                    <a :href="store.customerDetail.completed ? ((store.customerDetail.requiredActions?.length) || errorMsgsutability ? static_vars.domainURL + 'suitability' : static_vars.domainURL + 'select-a-broker') : static_vars.domainURL + 'user-details'"
                        class="button d-flex bold fillBtn zulu_btn mx-auto px-md-5"
                        v-else-if="!store.userSelectedAccount.demo && !isAlreadyCopied && !stillpendngOnbor() || errorMsgNoPlan">{{
            errorMsgNoSlots || errorMsgNoPlan ? $t('copy_strategy.purchaseNow') :
                $t('copy_strategy.takeSuitabilityTest') }}</a>
                    <a href="javascript:void(0)" class="button w-100 d-flex bold fillBtn zulu_btn"
                        v-else-if="stillpendngOnbor() && !errorMsgNoSlots" @click="copyTrader()"
                        :class="[{ 'disabled': !amount || parseInt(amount) <= 0 || !copyratio || copyratio <= 0 || copyratio > 1000 || store.userSelectedAccount.isLeader }]">{{ $t('copy_strategy.copyStrategy') }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- successfully bought -->
    <div class="modal fade muySellModal" :class="[{ 'show': successCopy }]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0 pb-0">
                    <button type="button" class="btn-close"
                        @click="successCopy = false; $parent.copyStrategy = false"></button>
                </div>
                <div class="modal-body mb-2">
                    <div class="text-center mb-3">
                        <v-lazy-image width="100" class="mb-3" src="/assets/images/success14789.png"
                            :alt="$t('copy_strategy.success')" />
                        <!-- <h5>You have successfully <span class="secondary">bought</span></h5> -->
                        <h5 class="mb-4">{{ $t('copy_strategy.youAreNowCopying') }} <span class="secondary">{{
            store.traderDetail.trader.stats.profile.name }}</span></h5>
                    </div>
                    <div class="successMessage bg-card radius10 flex-between p-2 my-2">
                        <div class="d-flex align-items-center">
                            <div class="vueRisk">
                                <span class="d-flex align-items-center justify-content-center me-2">
                                    <v-lazy-image height="40" width="40" class="rounded-circle border"
                                        :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + store.traderDetail.trader.stats.profile.zuluAccountId + '&ignore=false'"
                                        :alt="$t('copy_strategy.text1')" :title="$t('copy_strategy.text1')" />
                                </span>
                            </div>
                            <p class="medium mb-0">{{ store.traderDetail.trader.stats.profile.name }}</p>
                        </div>
                        <p class="mb-0 f-18 neutralGrey medium beforeBorder me-1">
                            {{ COMMON.getBaseCurrencySymbol() }}{{ amount || 0 }}</p>
                    </div>
                </div>
                <div class="modal-footers p-3 border-0">
                    <router-link :to="{ name: 'dashboard_portfolio' }"
                        @click="successCopy = false; $parent.copyStrategy = false"
                        class="button d-flex bold fillBtn zulu_btn">{{ $t('copy_strategy.continue') }}</router-link>
                </div>
            </div>
        </div>
    </div>
    <!-- Stop--->
    <div class="modal fade muySellModal" :class="[{ 'show': stopStrategy }]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0 pb-0">
                    <button type="button" class="btn-close"
                        @click="stopStrategy = false; $parent.copyStrategy = false"></button>
                </div>
                <div class="modal-body mb-2">
                    <div class="text-center mb-3">
                        <v-lazy-image width="100" class="mb-3" src="/assets/images/success14789.png"
                            :alt="$t('copy_strategy.success')" />
                        <h5><span class="secondary">{{ $t('copy_strategy.stop') }}</span>
                            {{ $t('copy_strategy.strategySuccessfully') }}</h5>
                    </div>
                    <div class="successMessage radius10 bg-card flex-between p-2 mb-2">
                        <div class="d-flex align-items-center">
                            <div class="vueRisk">
                                <span class="d-flex align-items-center justify-content-center me-3">
                                    <v-lazy-image height="40" width="40" class="rounded-circle border"
                                        :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + store.traderDetail.trader.stats.profile.zuluAccountId + '&ignore=false'"
                                        :alt="$t('copy_strategy.text1')" :title="$t('copy_strategy.text1')" />
                                </span>
                            </div>
                            <p class="medium mb-0">{{ store.traderDetail.trader.stats.profile.name }}</p>
                        </div>
                        <p class="mb-0 f-18 neutralGrey medium me-1 beforeBorder">
                            {{ COMMON.getBaseCurrencySymbol() }}{{ amount || 0 }}</p>
                    </div>
                </div>
                <div class="p-3 border-0">
                    <a href="javascript:void(0)" @click="stopStrategy = false; $parent.copyStrategy = false"
                        class="button d-flex bold fillBtn zulu_btn">{{ $t('copy_strategy.continue') }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Stop strategy Confirm-->
    <div class="modal fade muySellModal" :class="[{ 'show': stopStrategyConfirm }]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="toggleSlidebutton d-inline-flex align-items-center justify-content-center">
                            <h6 class="f-24 bold mb-0">{{ $t('copy_strategy.stopStrategy') }}</h6>
                        </div>
                    </div>
                    <button type="button" class="btn-close" @click="stopStrategyConfirm = false"></button>
                </div>
                <div class="modal-body mb-2">
                    <div class="text-center">
                        <p class="mb-2">{{ $t('copy_strategy.closeAllTradesfrom') }} <b class="secondary">{{
                                store.traderDetail.trader.stats.providerName }}</b> ?</p>
                        <p>{{$t('copy_strategy.byChoosingNo')}}</p>
                    </div>
                </div>
                <div class="p-3 border-0 flex-between">
                    <a href="javascript:void(0)" @click="stopStategy('yes')"
                        class="button me-2 w-50 d-flex bold fillBtn zulu_btn">{{$t('copy_strategy.yes')}}</a>
                    <a href="javascript:void(0)" @click="stopStategy('no')"
                        class="button w-50 d-flex bold borderBtn zulu_btn">{{$t('copy_strategy.no')}}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import $ from "jquery";
import _ from 'lodash'
import { useToast } from "vue-toastification";
export default {
    setup() {
        const store = myStore();
        const toast = useToast();
        return { store, toast };
    },
    data() {
        return {
            amount: 0,
            stopLoss: false,
            copytrades: false,
            takeProfitPrice: 0,
            copyratio: 0,
            isAlreadyCopied: false,
            successCopy: false,
            stopStrategy: false,
            advanceState: false,
            showAccount: false,
            updatedAccount: {},
            errorMsgNoSlots: false,
            errorMsgNoPlan: false,
            errorMsgsutability: false,
            stopStrategyConfirm: false,
            tradesLoading: false,
            availCapital: 0,
        };
    },
    watch: {
        amount() {
            if (!this.isAlreadyCopied) {
                this.onAmountChange()
            }
        },
        'store.copyTraders'() {
            this.defaultValues()
        }
    },
    methods: {
        accountList() {
            if (this.store.userTradingAccountsList.length && Object.keys(this.store.userSelectedAccount).length) {
                return this.store.userTradingAccountsList.filter(i => i.zuluAccountId != this.store.userSelectedAccount.zuluAccountId)
            } else {
                return []
            }
        },
        stillpendngOnbor() {
            if (this.store.userSelectedAccount && !this.store.userSelectedAccount.demo) {
                if (this.isAlreadyCopied) {
                    return true
                } else {
                    if (Object.keys(this.store.customerDetail).length) {
                        if (this.store.customerDetail.completed) {
                            if (!(this.store.customerDetail.requiredActions?.length)) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                }
            } else {
                return true
            }
        },
        changeBroker(list) {
            if (list.connectivityStatus === 'Connected') {
                this.showAccount = false
                this.store.$patch({ 'userSelectedAccount': list })
                this.updatedAccount = list
                if (this.store.copyTraders && this.store.copyTraders.availableCapitals && Object.keys(this.store.copyTraders.availableCapitals).length) {
                    for (let id in this.store.copyTraders.availableCapitals) {
                        if (this.store.userSelectedAccount && this.store.userSelectedAccount.brokerAccountId) {
                            if (parseInt(id) == parseInt(this.store.userSelectedAccount.brokerAccountId)) {
                                this.availCapital = this.store.copyTraders.availableCapitals[id]
                                this.amount = parseFloat(((this.store.copyTraders.availableCapitals[id] * 40) / 100))
                                this.amount = Number((this.amount).toPrecision(1))
                            }
                        }
                    }
                }
                this.store.getUserDashboardData({}, true, '', '10000')
                let form = {
                    'targetBrokerAccountId': list.brokerAccountId,
                    'page': 0,
                    'size': 10,
                    'sort': 'id,desc',
                };
                this.store.getProviderOpenTrades({}, true);
                this.store.userNotifications(form, false)
                if (this.$parent.copyORupdate()) {
                    this.isAlreadyCopied = true;
                    let invest = this.store.copyTraders.providers.filter((i) => i?.brokerAccountId == this.store.userSelectedAccount?.brokerAccountId && i?.zuluAccountId == this.store.userSelectedAccount?.zuluAccountId && i.providerId == parseInt(this.$route.params.id));
                    if (invest.length) {
                        this.amount = invest[0].capitalProtectionAmount;
                        this.takeProfitPrice = invest[0].stopOnProfit;
                        this.stopLoss = invest[0].capitalProtectionTrailing;
                        this.copyratio = invest[0].prorataPercent;
                    } else {
                        this.amount = 0;
                        this.stopLoss = false;
                        this.takeProfitPrice = 0;
                        this.copyratio = 0;
                        this.copytrades = false;
                    }

                } else {
                    this.isAlreadyCopied = false;
                    if (this.store.copyTraders && this.store.copyTraders.availableCapitals && Object.keys(this.store.copyTraders.availableCapitals).length) {
                        for (let id in this.store.copyTraders.availableCapitals) {
                            if (this.store.userSelectedAccount && this.store.userSelectedAccount.brokerAccountId) {
                                if (parseInt(id) == parseInt(this.store.userSelectedAccount.brokerAccountId)) {
                                    this.availCapital = this.store.copyTraders.availableCapitals[id]
                                    this.amount = parseFloat(((this.store.copyTraders.availableCapitals[id] * 40) / 100))
                                    this.amount = Number((this.amount).toPrecision(1))
                                    this.takeProfitPrice = 0;
                                    this.copyratio = 0;
                                    this.stopLoss = false;
                                    this.copytrades = false;
                                }
                            }
                        }
                    }

                }
            }
        },
        stopStategy(ans) {
            this.store.stopStategy({}, true, this.store.traderDetail.trader.stats.providerId, this.updatedAccount.zuluAccountId, 'stopStrategy', ans == 'yes' ? 'yes' : 'no', this).then(() => {
                this.stopStrategy = true;
                this.stopStrategyConfirm = false;
                let data = this.store.traderDetail;
                data.user.following = false;
                this.store.$patch({ traderDetail: data });
                this.getCopyLeaders()
            });
        },
        getCopyLeaders() {
            this.store.GetCopyTraders({}, false)
        },
        copyTrader() {
            if (this.store.userSelectedAccount.isLeader) {
                this.$parent.cantCopy()
            } else {
                let blockedIDs = ["371351", "371319", "371318", "371602", "371197"];
                if (!blockedIDs.includes(this.store.traderDetail.trader.stats.providerId)) {
                    let form = {
                        capitalProtectionAmount: this.COMMON.replaceCommaToDot(this.amount), // The amount to invest
                        capitalProtectionModeId: this.stopLoss ? 2 : 1, // 1 default, 2 for trailing stop loss
                        providerId: this.store.traderDetail.trader.stats.providerId, // The leader's id
                        stopOnProfit: this.COMMON.replaceCommaToDot(this.takeProfitPrice), // for take profit value,
                    };
                    if (!isNaN(this.copyratio) && parseFloat(this.copyratio) > 0) {
                        form["prorataPercent"] = this.COMMON.replaceCommaToDot(this.copyratio);
                    }
                    this.store.copyLeader(form, true, this.store.userSelectedAccount.zuluAccountId, this.copytrades, this).then(() => {
                        this.successCopy = true;
                        let data = this.store.traderDetail;
                        data.user.following = true;
                        this.store.$patch({ traderDetail: data });
                        this.$parent.getCopyStats(this.store.userSelectedAccount.zuluAccountId);
                        this.getCopyLeaders()
                    }).catch((error) => {
                        // if(parseInt(error.response.data.code) == 9026){
                        //     this.errorMsgNoSlots = true
                        // } else if(parseInt(error.response.data.code) == 1100){
                        //     this.errorMsgNoPlan = true
                        // } else 
                        if (parseInt(error.response.data.code) == 1013) {
                            this.errorMsgsutability = true
                        }
                    });
                }
            }
        },
        maxCurrentAmount() {
            if (this.store.copyTraders?.availableCapitals && Object.keys(this.store.copyTraders.availableCapitals).length) {
                for (let id in this.store.copyTraders.availableCapitals) {
                    if (this.store.userSelectedAccount?.brokerAccountId) {
                        if (id == this.store.userSelectedAccount.brokerAccountId) {
                            if (this.isAlreadyCopied) {
                                let copydata = this.store.copyTraders.providers.filter((i) => i?.brokerAccountId == this.store.userSelectedAccount?.brokerAccountId && i?.zuluAccountId == this.store.userSelectedAccount?.zuluAccountId && i.providerId == parseInt(this.$route.params.id));
                                this.amount = parseFloat(this.store.copyTraders?.availableCapitals[id]) + parseFloat(copydata?.[0]?.capitalProtectionAmount)
                            } else {
                                this.amount = (parseFloat(this.store.copyTraders.availableCapitals[id]) > 0) ? (parseFloat(this.store.copyTraders.availableCapitals[id]).toFixed(3)) : (parseFloat(this.store.copyTraders.availableCapitals[id]))
                            }
                        }
                    }
                }
            }
        },
        updateStategy() {
            if (this.store.userSelectedAccount.isLeader) {
                this.$parent.cantCopy()
            } else {
                let blockedIDs = ["371351", "371319", "371318", "371602", "371197"];
                if (!blockedIDs.includes(this.store.traderDetail.trader.stats.providerId)) {
                    let form = {
                        capitalProtectionAmount: this.COMMON.replaceCommaToDot(this.amount), // The amount to invest
                        capitalProtectionModeId: this.stopLoss ? 2 : 1, // 1 default, 2 for trailing stop loss
                        providerId: this.store.traderDetail.trader.stats.providerId, // The leader's id
                        stopOnProfit: this.COMMON.replaceCommaToDot(this.takeProfitPrice), // for take profit value,
                    };
                    if (!isNaN(this.copyratio) && parseFloat(this.copyratio) > 0) {
                        form["prorataPercent"] = this.COMMON.replaceCommaToDot(this.copyratio);
                    }
                    this.store.updateLeader(form, true, this.updatedAccount.zuluAccountId, this.copytrades, this).then(() => {
                        this.successCopy = true;
                        this.$parent.getCopyStats(this.updatedAccount.zuluAccountId);
                        let data = this.store.traderDetail;
                        data.user.following = true;
                        this.store.$patch({ traderDetail: data });
                        this.getCopyLeaders()
                    });
                }
            }
        },
        onAmountChange: _.debounce(function () {
            let amount = this.COMMON.replaceCommaToDot(this.amount)
            if (amount && amount > 0) {
                let payload = {
                    "providerId": this.store.traderDetail.trader.stats.providerId,
                    "capitalProtectionAmount": amount
                }
                this.store.prorata(payload, false, this.store.userSelectedAccount.zuluAccountId).then((res) => {
                    if (res) { this.copyratio = res }
                })
            }
        }, 500),
        allowedDotComma(e, f) {
            let value = e.target.value.replace(/[^0-9.,]/g, '');
            // Allow only one dot or one comma, but not both
            if (value.indexOf('.') !== -1 && value.indexOf(',') !== -1) {
                const firstDot = value.indexOf('.');
                const firstComma = value.indexOf(',');
                if (firstDot < firstComma) {
                    value = value.replace(/,/, '');
                } else {
                    value = value.replace(/\./, '');
                }
            }
            // Allow only one dot
            if (value.split('.').length > 2) {
                value = value.replace(/\.(?=.*\.)/, '');
            }
            // Allow only one comma
            if (value.split(',').length > 2) {
                value = value.replace(/,(?=.*,)/, '');
            }
            this[f] = value
        },
        removeLastDotComma(f) {
            this[f] = this[f].toString()
            if (this[f] && this[f].endsWith('.') || this[f] && this[f].endsWith(',')) {
                this[f] = this[f].slice(0, -1);
            }
            if (this[f].startsWith('.') || this[f].startsWith(',')) {
                this[f] = '0' + this[f];
            }
        },
        defaultValues() {
            if (!Object.keys(this.store.userSelectedAccount).length && this.store.allTypeAccount) {
                let data = {};
                if (this.store.allTypeAccount == 'ALL_LIVE') {
                    data = this.store.userTradingAccountsList.find(i => i.demo === false && i.connectivityStatus == 'Connected')
                    if (data !== undefined || data !== null) {
                        this.store.$patch({ 'allTypeAccount': null })
                        this.store.$patch({ 'userSelectedAccount': data })
                    }
                } else if (this.store.allTypeAccount == 'ALL_DEMO') {
                    data = this.store.userTradingAccountsList.find(i => i.demo === true)
                    if (data !== undefined || data !== null) {
                        this.store.$patch({ 'allTypeAccount': null })
                        this.store.$patch({ 'userSelectedAccount': data })
                    }
                }
                this.store.getUserDashboardData({}, true, '', '10000')
                let form = {
                    'targetBrokerAccountId': this.store.userSelectedAccount.brokerAccountId,
                    'page': 0,
                    'size': 10,
                    'sort': 'id,desc',
                };
                this.store.getProviderOpenTrades({}, true);
                this.store.userNotifications(form, false)
            }
            if (this.$parent.copyORupdate()) {
                this.isAlreadyCopied = true;
                if (this.store.copyTraders && this.store.copyTraders.availableCapitals && Object.keys(this.store.copyTraders.availableCapitals).length) {
                    for (let id in this.store.copyTraders.availableCapitals) {
                        if (this.store.userSelectedAccount && this.store.userSelectedAccount.brokerAccountId) {
                            if (parseInt(id) == parseInt(this.store.userSelectedAccount.brokerAccountId)) {
                                this.availCapital = this.store.copyTraders.availableCapitals[id]
                            }
                        }
                    }
                }
            } else {
                this.isAlreadyCopied = false;
            }
            if (Object.keys(this.store.copyTraders).length) {
                if (this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                    let provider = this.store.copyTraders.providers.filter((i) => i?.brokerAccountId == this.store.userSelectedAccount?.brokerAccountId && i?.zuluAccountId == this.store.userSelectedAccount?.zuluAccountId && i.providerId == parseInt(this.$route.params.id));
                    if (provider.length) {
                        this.amount = provider[0].capitalProtectionAmount;
                        this.takeProfitPrice = provider[0].stopOnProfit;
                        this.stopLoss = provider[0].capitalProtectionTrailing;
                        this.copyratio = provider[0].prorataPercent;
                    } else {
                        this.amount = 0;
                        this.stopLoss = false;
                        this.takeProfitPrice = 0;
                        this.copyratio = 0;
                        this.copytrades = false;
                    }
                } else {
                    this.amount = 0;
                    this.stopLoss = false;
                    this.takeProfitPrice = 0;
                    this.copyratio = 0;
                    this.copytrades = false;
                }
            } else {
                this.amount = 0;
                this.stopLoss = false;
                this.takeProfitPrice = 0;
                this.copyratio = 0;
                this.copytrades = false;
            }
            if (!this.isAlreadyCopied) {
                if (this.store.copyTraders && this.store.copyTraders.availableCapitals && Object.keys(this.store.copyTraders.availableCapitals).length) {
                    for (let id in this.store.copyTraders.availableCapitals) {
                        if (this.store.userSelectedAccount && this.store.userSelectedAccount.brokerAccountId) {
                            if (parseInt(id) == parseInt(this.store.userSelectedAccount.brokerAccountId)) {
                                this.availCapital = this.store.copyTraders.availableCapitals[id]
                                this.amount = parseFloat(((this.store.copyTraders.availableCapitals[id] * 40) / 100))
                                this.amount = Number((this.amount).toPrecision(1))
                            }
                        }
                    }
                }
            }
            if (this.isAlreadyCopied && this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                let data = this.store.copyTraders.providers.filter((i) => i?.brokerAccountId == this.store.userSelectedAccount?.brokerAccountId && i?.zuluAccountId == this.store.userSelectedAccount?.zuluAccountId);
                if (data.length) {
                    this.store.userTradingAccountsList.map(val => {
                        if (parseInt(data[0].zuluAccountId) == parseInt(val.zuluAccountId)) {
                            this.updatedAccount = val
                        }
                    })
                } else {
                    this.updatedAccount = {}
                }
            }
        }
    },
    mounted() {
        this.defaultValues()
        let _that = this
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".showClass").length) {
                _that.showAccount = false
            }
        });
    },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
